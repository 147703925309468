/* src/index.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "https://www.nerdfonts.com/assets/css/webfont.css";
/* Your custom styles go here */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tile-container{
  background: linear-gradient(0deg,rgba(0,0,0,.7) 0,rgba(0,0,0,0) 100%);
}

.tile-details{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: .7rem;
  z-index: 2;
  background: linear-gradient(0deg,rgba(0,0,0,.7) 0,rgba(0,0,0,0) 100%);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Adjust the alpha value for transparency */
}
  
.tile-container:hover .overlay {
  background: none
}

.tile-container *{
  transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
}
.tile-container:hover  .tile-poster{
  transform: scale(1.1);
}